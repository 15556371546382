<template>
  <div class="page">
    <v-chatRoom
      :chatRoomType="chatRoomType"
      :sendParams="sendParams"
      :getListParams="getListParams"
      :delMsgParams="delMsgParams"
    >
    </v-chatRoom>
  </div>
</template>

<script>
export default {
  name: "leaveMsgForSaleMen",
  data() {
    return {
      chatRoomType: 1,
      sendParams: {
        relationId: null,
      },
      getListParams: { relationId: null, id: null },
      delMsgParams: {},
    };
  },
  created() {
    //留言id
    if (this.$route.query.leaveWordId) {
      this.getListParams.id = this.$route.query.leaveWordId;
    }
    // 好物id
    if (this.$route.query.goodsId) {
      this.sendParams.relationId = this.$route.query.goodsId;
      this.getListParams.relationId = this.$route.query.goodsId;
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100%;
  box-sizing: border-box;
  background: #f9f9f9;
}
</style>